@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.eot");
  src: url("../fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter-Regular.ttf") format("truetype"),
    url("../fonts/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Regular.eot");
  src: url("../fonts/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.ttf") format("truetype"),
    url("../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body,
html {
  background-color: #ffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter" !important;
}

p,
span,
button,
a,
table,
thead,
td,
th,
input,
label {
  font-family: "SF Pro Display" !important;
}
label {
  color: #656b71 !important;
}
.sidebar {
  box-shadow: inset -1px 0px 0px #d9e1ec;
}

.match {
  background-color: #5b45e0;
  border-radius: 4px;
}

input,
.ant-input-affix-wrapper,
button {
  border-radius: 4px !important;
}
.card_background {
  background: rgb(91, 69, 224);
  background: linear-gradient(
    90deg,
    rgba(91, 69, 224, 1) 43%,
    rgba(0, 212, 255, 1) 100%
  );
}

.ant-input {
  background-color: transparent !important;
  background-image: none;
  border: 0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.ant-spin-blur::after {
  opacity: 1 !important;
}

.ant-spin-container::after {
  background: #fff !important;
}
